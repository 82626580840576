body {
  background-color: #000;
  color: #fff;
  /* min-height: 100vh; */
  padding: 0;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.transfer-input .MuiInput-underline:before {
  display: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: transparent !important;
}
/* .muidialog-paperscrollpaper {
  background-color: transparent !important;
} */
.declineButton {
  background: linear-gradient(
    92.79deg,
    #ba18b3 -32.2%,
    #ff564d 5.51%,
    #ff0000 54.01%,
    #c15900 110.93%
  ) !important ;
  color: #fff !important;
}
